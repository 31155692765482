import React from "react"
import { graphql } from "gatsby";
import {IndexPage} from "./index"

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["headerSection", "index", "common", "helpSection", "claimsSection", "mlp-testimonials", "navbar", "footer", "getQuoteModal", "threat-events", "extraBenefits", "stepsToProtectBusiness"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const MixSquadsLP = () => <IndexPage isMixsquadsLP />

export default MixSquadsLP